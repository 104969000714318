import * as React from "react";
import Container from "../components/Container/Container";
import TextLayout from "../layouts/TextLayout";

const TermsConditionsPage = () => {
  return (
    <TextLayout
      pageTitle={"Terms of Use | Omnia"}
      headerFixedOnMobile
      hideAdditionalSection
      title={"Terms of Use"}
    >
      <Container textLayout>
        <div className="inner-page-wrap__content">
          <p>
            Privacy Infrastructure Provider OÜ or any related entity (the
            "Company," "we," or "us") would like to inform you about the terms
            of use of the websites operated by us from which you are accessing
            this Terms of Use (the "Websites"); which may be accessed through
            HTML-formatted email message(s) that we send to you that link to
            this Terms of Use. Collectively, we refer to the above as the
            "Service".
          </p>

          <p>
            By accessing or using the Service you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
            access the Service.
          </p>

          <h2 class="h-3">Conditions of Use</h2>

          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use any of our Websites if you do not
            agree to take all of the terms and conditions stated below.
          </p>

          <h2 class="h-3">Service Liability</h2>

          <p>
            By accessing any part of the Websites or Service you agree and
            expressly understand that you do so at your sole risk. Our website,
            dashboard, nft pages, nfts, privacy relayers, nodes, smart contracts are provided "as
            is" and "as available" without warranties of any kind, whether
            express or implied.
          </p>

          <h2 class="h-3">Reservation of Rights</h2>

          <p>
            We reserve the right to request that you remove all links or any
            particular link to our Websites. You approve to immediately remove
            all links to our Websites upon request. We also reserve the right to
            amend these terms and conditions and its linking policy at any time.
            By continuously linking to our Websites, you agree to be bound to
            and follow these linking terms and conditions.
          </p>

          <p>
            Without prior approval and written permission, you may not create
            frames around our website that alter in any way the visual
            presentation or appearance.
          </p>

          <h2 class="h-3">Link Liability</h2>

          <p>
            Our offer contains links to external websites of third parties, the
            content of which we have no influence. For this reason, we cannot
            accept any liability for this external content. The respective
            provider or operator of the pages is always responsible for the
            content of the linked pages. The linked pages were checked for
            possible legal violations at the time of linking. No illegal content
            was discernible at the time the link was created.
          </p>

          <p>
            A permanent control of the content of the linked pages is not
            reasonable without concrete evidence of an infringement. As soon as
            we become aware of legal violations, we will remove such links
            immediately.
          </p>
        </div>
      </Container>
    </TextLayout>
  );
};

export default TermsConditionsPage;
